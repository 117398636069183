import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'loader',
    pathMatch: 'full'
  },
  {
    path: 'loader',
    loadChildren: () => import('./pages/loader/loader.module').then( m => m.LoaderPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'registrieren',
    loadChildren: () => import('./pages/registrieren/registrieren.module').then( m => m.RegistrierenPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'einstellungen',
    loadChildren: () => import('./pages/einstellungen/einstellungen.module').then( m => m.EinstellungenPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'noten',
    loadChildren: () => import('./pages/noten/noten.module').then( m => m.NotenPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'gegenstaende',
    loadChildren: () => import('./pages/gegenstaende/gegenstaende.module').then( m => m.GegenstaendePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'notizen',
    loadChildren: () => import('./pages/notizen/notizen.module').then( m => m.NotizenPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'zeiterfassung',
    loadChildren: () => import('./pages/zeiterfassung/zeiterfassung.module').then( m => m.ZeiterfassungPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'kalender',
    loadChildren: () => import('./pages/kalender/kalender.module').then( m => m.KalenderPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'stundenplan',
    loadChildren: () => import('./pages/stundenplan/stundenplan.module').then( m => m.StundenplanPageModule),
    canActivate: [AuthGuard]
  }





];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
