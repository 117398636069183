import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { IUntisApi } from './interfaces';
// import FormData from 'form-data';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  endpoint = 'https://schoolorganizer.digbizmistelbach.info/api/public/';

  constructor(private http: HttpClient, private nav: NavController) {}

  getHeaderOptions() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    if(!sessionStorage.getItem('key')){
      this.nav.navigateRoot('/');
      return;
    }
    headers = headers.set('key', sessionStorage.getItem('key'));
    return { headers };
  }

  //Online/CheckOnline/Login/Logout/Registrieren
  online(){
    return this.http.get(this.endpoint + 'online', this.getHeaderOptions());
  }

  checkLogin(){
    return this.http.get(this.endpoint + 'checkLogin', this.getHeaderOptions());
  }

  login(data) {
    const jsonvalue = JSON.stringify(data);
    let header = new HttpHeaders();
    header = header.set('Content-Type', 'application/json; charset=utf-8');
    const httpOptions = { headers: header };
    return this.http.post(this.endpoint + 'login', jsonvalue, httpOptions);
  }

  logout(){
    return this.http.get(this.endpoint + 'logout', this.getHeaderOptions());
  }

  passwortvergessen(data){
    const jsonvalue = JSON.stringify(data);
    let header = new HttpHeaders();
    header = header.set('Content-Type', 'application/json; charset=utf-8');
    const httpOptions = { headers: header };
    return this.http.post(this.endpoint + 'passwortvergessen', jsonvalue, httpOptions);
  }

  passwortreset(data){
    const jsonvalue = JSON.stringify(data);
    let header = new HttpHeaders();
    header = header.set('Content-Type', 'application/json; charset=utf-8');
    const httpOptions = { headers: header };
    return this.http.post(this.endpoint + 'passwortreset', jsonvalue, httpOptions);
  }

  registrieren(data){
    const jsonvalue = JSON.stringify(data);
    let header = new HttpHeaders();
    header = header.set('Content-Type', 'application/json; charset=utf-8');
    const httpOptions = { headers: header };
    return this.http.post(this.endpoint + 'registrieren', jsonvalue, httpOptions);
  }

  registrierenPasswort(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'registrieren/passwort', jsonvalue, this.getHeaderOptions());
  }

  registrierenName(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'registrieren/name', jsonvalue, this.getHeaderOptions());
  }

  registrierenGeburtsdatum(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'registrieren/geburtsdatum', jsonvalue, this.getHeaderOptions());
  }

  registrierenApps(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'registrieren/apps', jsonvalue, this.getHeaderOptions());
  }

  registrierenUntis(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'registrieren/untis', jsonvalue, this.getHeaderOptions());
  }

  registrierenUntisKlasse(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'registrieren/untisklasse', jsonvalue, this.getHeaderOptions());
  }

  selectUserdata(){
    return this.http.get(this.endpoint + 'selectUserdata', this.getHeaderOptions());
  }

  //Gegenstand
  selectGegenstaende(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'selectGegenstaende', jsonvalue, this.getHeaderOptions());
  }

  selectGegenstand(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'selectGegenstand', jsonvalue, this.getHeaderOptions());
  }

  insertGegenstand(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'insertGegenstand', jsonvalue, this.getHeaderOptions());
  }

  updateGegenstand(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'updateGegenstand', jsonvalue, this.getHeaderOptions());
  }

  deleteGegenstand(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'deleteGegenstand', jsonvalue, this.getHeaderOptions());
  }

  //Noten
  selectNoten(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'selectNoten', jsonvalue, this.getHeaderOptions());
  }

  selectNote(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'selectNote', jsonvalue, this.getHeaderOptions());
  }

  selectNotenByGegenstand(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'selectNotenByGegenstand', jsonvalue, this.getHeaderOptions());
  }

  selectNotenForDashboard(){
    return this.http.get(this.endpoint + 'selectNotenForDashboard', this.getHeaderOptions());
  }

  insertNote(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'insertNote', jsonvalue, this.getHeaderOptions());
  }

  updateNote(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'updateNote', jsonvalue, this.getHeaderOptions());
  }

  deleteNote(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'deleteNote', jsonvalue, this.getHeaderOptions());
  }

  //Notizen
  selectNotizen(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'selectNotizen', jsonvalue, this.getHeaderOptions());
  }

  selectNotiz(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'selectNotiz', jsonvalue, this.getHeaderOptions());
  }

  selectNotizenByGegenstand(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'selectNotizenByGegenstand', jsonvalue, this.getHeaderOptions());
  }

  selectNotizenForDashboard(){
    return this.http.get(this.endpoint + 'selectNotizenForDashboard', this.getHeaderOptions());
  }

  insertNotiz(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'insertNotiz', jsonvalue, this.getHeaderOptions());
  }

  updateNotiz(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'updateNotiz', jsonvalue, this.getHeaderOptions());
  }

  deleteNotiz(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'deleteNotiz', jsonvalue, this.getHeaderOptions());
  }

  //Study-Timer Zeiterfassung
  selectZeiterfassungen(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'selectZeiterfassungen', jsonvalue, this.getHeaderOptions());
  }

  selectZeiterfassung(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'selectZeiterfassung', jsonvalue, this.getHeaderOptions());
  }

  selectZeiterfassungenByGegenstand(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'selectZeiterfassungenByGegenstand', jsonvalue, this.getHeaderOptions());
  }

  selectZeiterfassungenForDashboard(){
    return this.http.get(this.endpoint + 'selectZeiterfassungForDashboard', this.getHeaderOptions());
  }

  insertZeiterfassung(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'insertZeiterfassung', jsonvalue, this.getHeaderOptions());
  }

  updateZeiterfassung(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'updateZeiterfassung', jsonvalue, this.getHeaderOptions());
  }

  deleteZeiterfassung(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'deleteZeiterfassung', jsonvalue, this.getHeaderOptions());
  }

  //Kalender
  selectTermine(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'selectTermine', jsonvalue, this.getHeaderOptions());
  }

  selectTermin(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'selectTermin', jsonvalue, this.getHeaderOptions());
  }

  selectTermineByGegenstand(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'selectTermineByGegenstand', jsonvalue, this.getHeaderOptions());
  }

  selectTermineForDashboard(){
    return this.http.get(this.endpoint + 'selectTermineForDashboard', this.getHeaderOptions());
  }

  selectTermintyp(){
    return this.http.get(this.endpoint + 'selectTermintyp', this.getHeaderOptions());
  }

  insertTermin(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'insertTermin', jsonvalue, this.getHeaderOptions());
  }

  updateTermin(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'updateTermin', jsonvalue, this.getHeaderOptions());
  }

  deleteTermin(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'deleteTermin', jsonvalue, this.getHeaderOptions());
  }

  //Stundenplan
  selectStundenplan(){
    return this.http.get(this.endpoint + 'selectStundenplan', this.getHeaderOptions());
  }

  updateStundenplan(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'updateStundenplan', jsonvalue, this.getHeaderOptions());
  }

  //Untis
  selectClasses(data: IUntisApi){
    const jsonvalue = JSON.stringify(data);
    return this.http.post('https://schoolorganizer-untis2.web.app/selectClasses', jsonvalue, this.getHeaderOptions()).toPromise();
  }

  selectTimetable(data: IUntisApi){
    const jsonvalue = JSON.stringify(data);
    return this.http.post('https://schoolorganizer-untis2.web.app/selectTimetable', jsonvalue, this.getHeaderOptions());
  }

  selectTimegrid(data: IUntisApi){
    const jsonvalue = JSON.stringify(data);
    return this.http.post('https://schoolorganizer-untis2.web.app/selectTimegrid', jsonvalue, this.getHeaderOptions());
  }

  //Einstellungen
  selectProfil(){
    return this.http.get(this.endpoint + 'selectProfil', this.getHeaderOptions());
  }

  deleteProfilbild(){
    return this.http.get(this.endpoint + 'deleteProfilbild', this.getHeaderOptions());
  }

  selectAccount(){
    return this.http.get(this.endpoint + 'selectAccount', this.getHeaderOptions());
  }

  selectEinstellungen(){
    return this.http.get(this.endpoint + 'selectEinstellungen', this.getHeaderOptions());
  }

  updateProfil(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'updateProfil', jsonvalue, this.getHeaderOptions());
  }

  updatePasswort(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'updatePasswort', jsonvalue, this.getHeaderOptions());
  }

  updateEinstellungen(data){
    const jsonvalue = JSON.stringify(data);
    return this.http.post(this.endpoint + 'updateEinstellungen', jsonvalue, this.getHeaderOptions());
  }

  uploadBild(data){
    let header = new HttpHeaders();
    header = header.set('Content-Type', 'multipart/form-data');
    const httpOptions = { headers: header };
    return this.http.post(this.endpoint + 'uploadBild', data, httpOptions);
  }
}
