import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { ICheckLogin } from './interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private api: ApiService, private nav: NavController) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    //Auf Login prüfen
    try{
      if(!sessionStorage.getItem('key')){
        this.nav.navigateRoot('/');
        return false;
      }
      this.api.checkLogin().subscribe((data: ICheckLogin) => {
        if(data.error !== false || data.eingeloggt !== true){
          this.nav.navigateRoot('/');
          return false;
        }
        return true;
      }, (err) => {
        this.nav.navigateRoot('/');
        return false;
      });
    }
    catch{
      this.nav.navigateRoot('/');
      return false;
    }
  }
}
