import { Component, OnInit } from '@angular/core';
import { isPlatform, MenuController } from '@ionic/angular';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { ApiService } from './services/api.service';
import { AppsService } from './services/apps.service';
import { sha512 } from 'js-sha512';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public appPages = [
    { title: 'Home', url: '/home', icon: 'home', show: true },
    { title: 'Studenplan', url: '/stundenplan', icon: 'time', show: this.as.isUntis() },
    { title: 'Gegenstände', url: '/gegenstaende', icon: 'list', show: true },
    { title: 'Noten', url: '/noten', icon: 'ribbon', show: this.as.isNoten() },
    { title: 'Notizen', url: '/notizen', icon: 'document-text', show: this.as.isNotizen() },
    { title: 'Kalender', url: '/kalender', icon: 'calendar', show: this.as.isKalender() },
    { title: 'StudyTimer', url: '/zeiterfassung', icon: 'timer', show: this.as.isTimer() },
    { title: 'Einstellungen', url: '/einstellungen', icon: 'settings', show: true },
  ];

  noMenuPages = [
    '/loader',
    '/login',
    '/login/passwortvergessen',
    '/login/passwortreset',
    '/registrieren',
    '/registrieren/passwort',
    '/registrieren/name',
    '/registrieren/geburtsdatum',
    '/registrieren/apps',
    '/registrieren/untis',
    '/registrieren/untis-klasse',
    '/registrieren/gegenstand',
    '/'
  ];

  public isIos = false;

  name: string;
  email: string;
  avatarurl: string;

  constructor(private router: Router, private menu: MenuController, private api: ApiService, private as: AppsService) {
    //Um SideMenu auf bestimmten Seiten auszublenden
    router.events.subscribe((event: RouterEvent) => {
      if(event instanceof NavigationEnd) {
        const path = window.location.pathname;
        //let found = false;
        this.menu.enable(true);
        this.loadMenu();
        for(const page of this.noMenuPages){
          if(path===page) {
            this.menu.enable(false);
            //found = true;
            return;
          }
        }
        this.checkOnline();
      }
    });
  }

  ngOnInit() {
    if (isPlatform('hybrid')) {
      this.isIos = true;
    }
  }

  public refreshApps() {
    this.as = new AppsService();
    this.appPages[1].show = this.as.isUntis();
    this.appPages[3].show = this.as.isNoten();
    this.appPages[4].show = this.as.isNotizen();
    this.appPages[5].show = this.as.isKalender();
    this.appPages[6].show = this.as.isTimer();
  }

  loadMenu() {
    this.name = sessionStorage.getItem('vorname') + ' ' + sessionStorage.getItem('zuname');
    this.email = sessionStorage.getItem('email');
    this.refreshApps();

    let url: string;
    try{
      url = sessionStorage.getItem('profilbild');
      if(!url || url === 'null'){
        throw new Error('no url');
      }
      url = 'https://schoolorganizer.digbizmistelbach.info/api/public/selectProfilbild/' + sessionStorage.getItem('key');
    }
    catch{
      try{
        url = 'https://avatars.dicebear.com/api/bottts/' + sha512(sessionStorage.getItem('email')) + '.svg';
      }
      catch{
        this.avatarurl = 'https://avatars.dicebear.com/api/bottts/schoolorganizer.svg';
      }
    }
    if(url) {
      this.avatarurl = url;
    }
    else{
      this.avatarurl = 'https://avatars.dicebear.com/api/bottts/schoolorganizer.svg';
    }

  }

  checkOnline() {
    this.api.online().subscribe((res)=>{},
    (err)=> {
      alert('Es konnte keine Verbindung zum Server hergestellt werden! 🙁\nVersuchen Sie es später erneut!');
    });
  }

}
