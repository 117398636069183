import { Injectable } from '@angular/core';
import { IApps } from './interfaces';

@Injectable({
  providedIn: 'root'
})
export class AppsService {

  apps: IApps;

  constructor() {
    this.einlesen();
  }

  einlesen(){
    try{
      this.apps = (JSON.parse(sessionStorage.getItem('einstellungen'))).apps;
    }
    catch{
      const data: IApps = {
        noten: true,
        notizen: true,
        kalender: true,
        untis: true,
        timer: true
      };
      this.apps = data;
    }
  }

  isNoten(): boolean{
    return this.apps.noten;
  }

  isNotizen(): boolean{
    return this.apps.notizen;
  }

  isKalender(): boolean{
    return this.apps.kalender;
  }

  isUntis(): boolean{
    return this.apps.untis;
  }

  isTimer(): boolean{
    return this.apps.timer;
  }

}
